<template>
        <div class="schedule" v-cloak=" " v-show="show_campaign">
                <div class="schedule-back">
                        <img @click.prevent="searchCampaigns" src="~/assets/images/client/ic_keyboard_arrow_left_black.svg">
                        <strong>Back to campaigns</strong>
                </div>
                <div class="schedule-header">
                        <div class="left">
                                <h3 class="schedule-title">{{ campaign.name }}</h3>
                                <span>{{ campaign.blast_time }} - {{ blastTime(campaign.blast_date) }}</span>
                        </div>
                        <div class="buttons right">
                                <button @click.prevent="emit('creators-edit-campaign', campaign.id)" class="blue-btn"
                                        type="button" v-show="status != 2">Edit</button>
                                <button @click.prevent="emit('modal-delete-campaign', campaign.id)" class="white-btn"
                                        type="button">Delete</button>
                        </div>
                </div>
                <br>
                <div class="schedule-details">
                        <div class="left">
                                <div>
                                        {{ campaign.ivr_tree_name }}
                                        <br>
                                        <strong>IVR</strong>
                                </div>
                                <div>
                                        {{ campaign.filename }}
                                        <br>
                                        <strong>CSV</strong>
                                </div>
                                <div>
                                        {{ campaign.campaign_record_count }}
                                        <br>
                                        <strong>Volume</strong>
                                </div>
                        </div>
                        <div class="right">
                                <div>
                                        {{ campaign.caller_id }}
                                        <br>
                                        <strong>Caller ID</strong>
                                </div>
                                <div>
                                        {{ campaign.sms_masking }}
                                        <br>
                                        <strong>SMS Masking</strong>
                                </div>
                        </div>
                </div>
        </div>
</template>

<script setup>
defineProps([
        "show_campaign",
        "searchCampaigns",
        "campaign",
        "blastTime",
        "status",
])

const emit = defineEmits([
        "creators-edit-campaign",
        "modal-delete-campaign",
])
</script>
