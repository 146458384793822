<template>
        <div class="modal fade information-popup" id="modal-campaign-success" role="dialog">
                <div class="modal-dialog">
                        <!-- Modal content -->
                        <div class="modal-content">
                                <div class="modal-body">
                                        <img src="~/assets/images/client/success.svg">
                                        <h1>Campaign successfully created!</h1>
                                        <button @click.prevent="emit('view-campaign', campaign.id)" class="blue-btn"
                                                data-dismiss="modal" type="button">View Campaign</button>
                                </div>
                        </div>
                </div>
        </div>
        <div class="modal fade information-popup" id="modal-campaign-update" role="dialog">
                <div class="modal-dialog">
                        <!-- Modal content -->
                        <div class="modal-content">
                                <div class="modal-body">
                                        <img src="~/assets/images/client/success.svg">
                                        <h1>Campaign successfully updated!</h1>
                                        <button @click.prevent="emit('view-campaign', campaign.id)" class="blue-btn"
                                                data-dismiss="modal" type="button">Close</button>
                                </div>
                        </div>
                </div>
        </div>
        <div class="modal fade information-popup" id="modal-ivr-success" role="dialog">
                <div class="modal-dialog">
                        <!-- Modal content -->
                        <div class="modal-content">
                                <div class="modal-body">
                                        <img src="~/assets/images/client/success.svg">
                                        <h1>Call flow successfully created!</h1>
                                        <button @click.prevent="emit('view-ivr-tree')" class="blue-btn" data-dismiss="modal"
                                                type="button">View Call flow</button>
                                </div>
                        </div>
                </div>
        </div>
        <div class="modal fade information-popup" id="modal-ivr-update" role="dialog">
                <div class="modal-dialog">
                        <!-- Modal content -->
                        <div class="modal-content">
                                <div class="modal-body">
                                        <img src="~/assets/images/client/success.svg">
                                        <h1>IVR Tree successfully updated!</h1>
                                        <button class="blue-btn" data-dismiss="modal" type="button">Close</button>
                                </div>
                        </div>
                </div>
        </div>
        <div class="modal fade information-popup" id="modal-ivr-tree-group-success" role="dialog">
                <div class="modal-dialog">
                        <div class="modal-content">
                                <div class="modal-body">
                                        <img src="~/assets/images/client/success.svg">
                                        <h1>Call flow group successfully created!</h1>
                                        <button class="blue-btn" data-dismiss="modal" type="button">Close</button>
                                </div>
                        </div>
                </div>
        </div>
</template>

<script setup>
defineProps([
        "campaign",
])

const emit = defineEmits([
        "view-ivr-tree",
        "view-campaign",
])
</script>
