<template>
        <div class="modal fade" id="modal-campaign-create" role="dialog">
                <div class="modal-dialog">
                        <!-- Modal content -->
                        <div class="modal-content">
                                <div class="modal-header">
                                        <h4 class="modal-title">Campaigns</h4>
                                        <button class="close" data-dismiss="modal" type="button">×</button>
                                </div>
                                <div class="modal-body">
                                        <SharedVueErrorMessages :show_errors="show_errors" :errors="errors" />
                                        <!-- TODO(aes): implement dms? -->
                                        <form @submit.prevent="emit('submit')">
                                                <div v-if="for_edit">
                                                        <div class="left">
                                                                <div class="ivr-div">
                                                                        <label for="selected_ivr">Selected IVR:</label>
                                                                        <div>{{ ivr_name }}</div>
                                                                </div>
                                                                <label for="campaign_name">Campaign Name</label>
                                                                <input v-model="campaign.name" type="text" name="campaign[name]"
                                                                        required="required" id="campaign-name"
                                                                        @keyup="setCampaignName">
                                                                <div class="datetimepicker-wrapper" style="margin-right: 24px;">
                                                                        <label for="campaign_date">Schedule</label>
                                                                        <vue3-datepicker ref="dateEdit"
                                                                                :model-value="campaign_blast_date"
                                                                                v-bind:disabled-dates="disabledDates"
                                                                                placeholder="Select Schedule"
                                                                                required="required" id="campaign-schedule"
                                                                                style="cursor: pointer;" autocomplete="off"
                                                                                inputFormat="dd MMM yyyy"
                                                                                @update:model-value="on_campaign_blast_date"
                                                                                @selected="setBlastDate"
                                                                                @closed="onCloseEdit" />
                                                                        <img src="~/assets/images/client/picker_calendar.svg"
                                                                                alt="date picker">
                                                                </div>
                                                                <div class="datetimepicker-wrapper">
                                                                        <label for="campaign_time">Time</label>
                                                                        <time-picker v-model:value="campaign.blast_time"
                                                                                :minute-interval="1" style="cursor: pointer;"
                                                                                @change="setBlastTime" />
                                                                        <img src="~/assets/images/client/picker_time.svg"
                                                                                alt="time picker">
                                                                </div>
                                                                <div class="clear"></div>
                                                                <div class="clear"></div>
                                                                <div class="retry-attemps">
                                                                        <label for="campaign_Retry attempt if (Optional)">Retry
                                                                                attempt if (optional)</label>
                                                                        <div class="retry-left">
                                                                                <div class="if-unanswered">
                                                                                        Unanswered
                                                                                        <label class="unanswered-switch">
                                                                                                <input id="unanswered-switch"
                                                                                                        type="checkbox"
                                                                                                        v-model="campaign.unanswered"
                                                                                                        @click="unansweredOpt">
                                                                                                <span
                                                                                                        class="unanswered-slider round"></span>
                                                                                        </label>
                                                                                </div>
                                                                                <div class="if-busy">
                                                                                        Busy
                                                                                        <label class="busy-switch">
                                                                                                <input id="busy-switch"
                                                                                                        type="checkbox"
                                                                                                        v-model="campaign.busy"
                                                                                                        @click="busyOpt">
                                                                                                <span
                                                                                                        class="busy-slider round"></span>
                                                                                        </label>
                                                                                </div>
                                                                                <div class="if-unreachable">
                                                                                        Unreachable
                                                                                        <label class="unreachable-switch">
                                                                                                <input id="unreachable-switch"
                                                                                                        type="checkbox"
                                                                                                        v-model="campaign.unreachable"
                                                                                                        @click="unreachableOpt">
                                                                                                <span
                                                                                                        class="unreachable-slider round"></span>
                                                                                        </label>
                                                                                </div>
                                                                        </div>
                                                                        <div class="retry-right">
                                                                                <div class="datetimepicker-wrapper">
                                                                                        <label for="campaign_time">Time</label>
                                                                                        <time-picker :minute-interval="1"
                                                                                                v-model:value="campaign.blast_attempts[1].blast_at"
                                                                                                @change="setRetryBlastTime"
                                                                                                v-bind:class="{ active: campaign.isTimeEnable, inactive: !campaign.isTimeEnable }"
                                                                                                style="cursor: pointer" />
                                                                                        <img src="~/assets/images/client/clear.gif"
                                                                                                v-bind:class="{ inactive: !campaign.isTimeEnable, active: campaign.isTimeEnable }">
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div class="right">
                                                                <div class="upload-card">
                                                                        <div class="file-btn">
                                                                                <img src="~/assets/images/client/ic_drag.png">
                                                                                <br>
                                                                                <br>
                                                                                <p>Drag and drop to upload a file</p>
                                                                                <div id="upload-csv-dummy">
                                                                                        <span class="upload-filename campaign">Upload
                                                                                                CSV</span>
                                                                                        <img
                                                                                                src="~/assets/images/client/upload.svg">
                                                                                </div>
                                                                                <br>
                                                                                <br>
                                                                                <div style="color: #00a8fb;">
                                                                                        <img src="~/assets/images/client/folder.svg"
                                                                                                style="margin-right: 12px;">
                                                                                        CSV Variables
                                                                                </div>
                                                                                <div id="variable-tags">
                                                                                        <ul
                                                                                                style="display: flex; flex-wrap: wrap;">
                                                                                        </ul>
                                                                                </div>
                                                                                <input ref="file" id="create-campaign-csv"
                                                                                        accept="text/csv" type="file"
                                                                                        name="campaign[file]"
                                                                                        class="upload-input"
                                                                                        @change="handleFileUpload">
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div class="buttons">
                                                                <button class="white-btn" data-dismiss="modal" type="button">
                                                                        Cancel
                                                                </button>
                                                                <button type="submit" class="gray-btn"
                                                                        id="campaign_form_button">
                                                                        Edit Campaign
                                                                </button>
                                                        </div>
                                                </div>

                                                <div v-else>
                                                        <div class="left">
                                                                <div class="ivr-div">
                                                                        <label for="selected_ivr">Selected IVR:</label>
                                                                        <div>{{ ivr_name }}</div>
                                                                </div>
                                                                <label for="campaign_name">Campaign Name</label>
                                                                <input v-model="campaign.name" type="text" name="campaign[name]"
                                                                        required="required" id="campaign-name"
                                                                        @keyup="setCampaignName">
                                                                <div class="datetimepicker-wrapper" style="margin-right: 24px;">
                                                                        <label for="campaign_date">Schedule</label>
                                                                        <vue3-datepicker ref="dateCreate"
                                                                                :model-value="campaign_blast_date"
                                                                                v-bind:disabled-dates="disabledDates"
                                                                                placeholder="Select Schedule"
                                                                                bootstrap-styling="true" required="required"
                                                                                id="campaign-schedule" style="cursor: pointer;"
                                                                                autocomplete="off" inputFormat="dd MMM yyyy"
                                                                                @update:model-value="on_campaign_blast_date"
                                                                                @selected="setBlastDate"
                                                                                @closed="onCloseCreate" />
                                                                        <img src="~/assets/images/client/picker_calendar.svg"
                                                                                alt="date picker">
                                                                </div>
                                                                <div class="datetimepicker-wrapper">
                                                                        <label for="campaign_time">Time</label>
                                                                        <time-picker
                                                                                v-model="campaign.blast_attempts[0].blast_at"
                                                                                :minute-interval="1" style="cursor: pointer;"
                                                                                @change="setBlastTime" />
                                                                        <img class="active"
                                                                                src="~/assets/images/client/clear.gif"
                                                                                alt="time picker">
                                                                </div>
                                                                <div class="clear"></div>
                                                                <div class="retry-attemps">
                                                                        <label for="campaign_Retry attempt if (Optional)">Retry
                                                                                attempt if (optional)</label>
                                                                        <div class="retry-left">
                                                                                <div class="if-unanswered">
                                                                                        Unanswered
                                                                                        <label class="unanswered-switch">
                                                                                                <input id="unanswered-switch"
                                                                                                        type="checkbox"
                                                                                                        v-model="campaign.unanswered"
                                                                                                        @click="unansweredOpt">
                                                                                                <span
                                                                                                        class="unanswered-slider round"></span>
                                                                                        </label>
                                                                                </div>
                                                                                <div class="if-busy">
                                                                                        Busy
                                                                                        <label class="busy-switch">
                                                                                                <input id="busy-switch"
                                                                                                        type="checkbox"
                                                                                                        v-model="campaign.busy"
                                                                                                        @click="busyOpt">
                                                                                                <span
                                                                                                        class="busy-slider round"></span>
                                                                                        </label>
                                                                                </div>
                                                                                <div class="if-unreachable">
                                                                                        Unreachable
                                                                                        <label class="unreachable-switch">
                                                                                                <input id="unreachable-switch"
                                                                                                        type="checkbox"
                                                                                                        v-model="campaign.unreachable"
                                                                                                        @click="unreachableOpt">
                                                                                                <span
                                                                                                        class="unreachable-slider round"></span>
                                                                                        </label>
                                                                                </div>
                                                                        </div>
                                                                        <div class="retry-right">
                                                                                <div class="datetimepicker-wrapper">
                                                                                        <label for="campaign_time">Time</label>
                                                                                        <time-picker :minute-interval="1"
                                                                                                v-model="campaign.blast_attempts[1].blast_at"
                                                                                                @change="setRetryBlastTime"
                                                                                                v-bind:class="{ active: campaign.isTimeEnable, inactive: !campaign.isTimeEnable }"
                                                                                                style="cursor: pointer" />
                                                                                        <img src="~/assets/images/client/clear.gif"
                                                                                                v-bind:class="{ inactive: !campaign.isTimeEnable, active: campaign.isTimeEnable }">
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div class="right">
                                                                <div class="upload-card">
                                                                        <div class="file-btn">
                                                                                <br>
                                                                                <p>Drag and drop to upload a file</p>
                                                                                <div id="upload-csv-dummy">
                                                                                        <span class="upload-filename campaign">Upload
                                                                                                CSV</span>
                                                                                        <img
                                                                                                src="~/assets/images/client/upload.svg">
                                                                                </div>
                                                                                <br>
                                                                                <br>
                                                                                <div style="color: #00a8fb;">
                                                                                        <img src="~/assets/images/client/folder.svg"
                                                                                                style="margin-right: 12px;">
                                                                                        CSV Variables
                                                                                </div>
                                                                                <div id="variable-tags">
                                                                                        <ul
                                                                                                style="display: flex; flex-wrap: wrap;">
                                                                                        </ul>
                                                                                </div>
                                                                                <input ref="file" id="create-campaign-csv"
                                                                                        required="required" accept="text/csv"
                                                                                        type="file" name="campaign[file]"
                                                                                        class="upload-input"
                                                                                        @change="handleFileUpload">
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div class="buttons">
                                                                <button class="white-btn" data-dismiss="modal" type="button"
                                                                        @click="grayBtn">
                                                                        Cancel
                                                                </button>
                                                                <button type="submit" class="gray-btn"
                                                                        id="campaign_form_button">
                                                                        Add Campaign
                                                                </button>
                                                        </div>
                                                </div>
                                        </form>
                                </div>
                        </div>
                </div>
        </div>
</template>

<script setup>
import Vue3Datepicker from 'vue3-datepicker'

defineProps([
        "for_edit",
        "ivr_name",
        "disabledDates",
        "setBlastDate",
        "setBlastTime",
        "setRetryTime",
        "setRetryBlastTime",
        "unansweredOpt",
        "busyOpt",
        "unreachableOpt",
        "handleFileUpload",
        "show_errors",
        "errors",
])

const campaign = defineModel("campaign")
const campaign_blast_date = computed(() => {
        if (!campaign.value.blast_date)
                return null
        if (typeof campaign.value.blast_date === "string")
                return new Date(campaign.value.blast_date)
        return campaign.value.blast_date
})
const on_campaign_blast_date = (value) => campaign.value.blast_date = value

const emit = defineEmits(["submit"])

// NOTE(aes): not sure why onClose handlers are firing twice...
const dateEdit = ref(null)
const onCloseEdit = () => {
        dateEdit.value.inputRef.blur()
}

const dateCreate = ref(null)
const onCloseCreate = () => {
        dateCreate.value.inputRef.blur()
}
</script>
