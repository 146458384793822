<template>
        <div class="header d-flex justify-content-between" id="search_campaign">
                <div class="left-header w-50 d-flex align-items-center">
                        <div>
                                <a href="#menu-toggle" id="menu-toggle">
                                        <img src="~/assets/images/client/ic_keyboard_arrow_right_black.svg">
                                </a>
                                <img @click="searchCampaigns" src="~/assets/images/client/ic_search.svg">
                        </div>
                        <form @submit.prevent="searchCampaigns">
                                <input class="header-search" name="search" placeholder="Search for campaign" type="search"
                                        id="search_input">
                        </form>
                </div>
                <div class="right-header w-50 d-flex justify-content-end">
                        <!-- TODO(aes): dms -->
                        <!-- - if user_is_dms_enabled? -->
                        <div class="profile ml-3 d-flex align-items-center">
                                <NuxtLink href="/profiles" id="profile-btn">
                                        <div>{{ firstNameLetter }}</div>
                                </NuxtLink>
                                <a href="#" id="logout-btn" @click="emit('logout', 'Logging out?')">
                                        <img src="~/assets/images/client/ic_exit.svg">
                                </a>
                        </div>
                </div>
        </div>
        <!-- TODO(aes): CreateGroupModal.vue -->
</template>

<script setup>
defineProps(["firstNameLetter"])
const emit = defineEmits(["logout"])
</script>

<script>
/**
 * source: app/assets/javascript/call_out/search_campaign.js
 */

export default {
        // el: '#search_campaign',
        data() {
                return {
                        show_search_list: false,
                        show_campaign_list: false,
                        wallet: '',
                        show_campaign: false,
                        sms_summary: '',
                        is_loading: false,
                        campaigns: {
                                data: [],
                                total_items: 0,
                                per_page: 2,
                                from: 1,
                                to: 0,
                                current_page: 1,
                                page_total: 0
                        },
                        campaign: '',
                        isIvrGroupsLoading: false
                }
        },
        computed: {
                currentIvrGroup() {
                        return this.$store.getters.getActiveIvrGroup
                },
                isEndOfIvrGroups() {
                        return !this.hasMoreIvrGroups
                },
                isIvrGroupsInitializing() {
                        return this.isIvrGroupsLoading && this.$store.getters.getIvrTreeGroups.length == 0
                },
                hasMoreIvrGroups() {
                        return this.$store.getters.hasMoreIvrGroups
                },
                ivrTreeGroups() {
                        return this.$store.getters.getIvrTreeGroups
                }
        },
        watch: {
                show_search_list(value) {
                        this.$emit("update:show_search_list", value)
                }
        },
        methods: {

                searchCampaigns() {
                        const { $emit } = useNuxtApp()

                        let searchInput = '';
                        $emit("searched-campaign-hide-others")
                        this.is_loading = true;
                        console.log('*** Searching Campaigns - search_campaign ***');

                        searchInput = $('#search_input').val();
                        if ($('#search_input').val() != '') {
                                console.log("SETTING UP DATA VALUE FOR content-search");
                                $('.content-search').data('input', searchInput)
                                $('.termsandconditions').hide();
                                $('.reports-summary').hide();
                                $('.help-wrap').hide();
                                $('.content#ivr-tree').hide();
                        }

                        let url = '/searchs/index.json';

                        console.log('searchInput: ' + searchInput);
                        axios.get(url, {
                                params: {
                                        page: this.campaigns.current_page,
                                        search: searchInput
                                }
                        }).then(response => {
                                $emit("searched-campaign-campaigns", response.data)
                                $emit("searched-campaign-is-loading", false)
                                console.log(this.is_loading);
                        }).catch(error => {
                                console.log("FAIL!!!")
                                console.log(error);
                        }).finally(() => {
                                this.show_search_list = true;
                                $emit("searched-campaign-show-campaign-list", true)
                        });
                },
                async initializeIvrGroups() {
                        try {
                                this.isIvrGroupsLoading = true
                                await this.$store.dispatch('setDefaultIvrTreeGroup')
                        } catch (e) {
                                // TODO: Handle error
                                console.error(e)
                        } finally {
                                this.isIvrGroupsLoading = false
                        }
                },
                ivrGroupSelected(ivrGroup) {
                        this.$store.dispatch('selectIvrGroup', ivrGroup)
                },
                async handleIvrGroupScroll(event) {
                        if (this.hasScrolledToBottom(event.target) && this.hasMoreIvrGroups) {
                                await this.$store.dispatch('guardAction', {
                                        name: 'ivrTreeGroups',
                                        action: async () => {
                                                try {
                                                        this.isIvrGroupsLoading = true
                                                        await this.$store.dispatch('fetchIvrTreeGroups', {
                                                                page: this.$store.getters.getCurrentPage + 1,
                                                                append: true
                                                        })
                                                } catch (e) {
                                                        return Promise.reject(e)
                                                } finally {
                                                        this.isIvrGroupsLoading = false
                                                }
                                        }
                                })
                        }
                },
                hasScrolledToBottom(element) {
                        // https://stackoverflow.com/a/34550171
                        return Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) <= 3.0
                },
                async ivrTreeGroupCreatedListener(ivrTreeGroup) {
                        await this.$store.dispatch('setDefaultIvrTreeGroup', { forceUpdate: true })
                },
                attachEventListeners() {
                        if (window.eventBus) {
                                window.eventBus.$on('ivr-tree-group-created', this.ivrTreeGroupCreatedListener)
                        }
                },
                detachEventListeners() {
                        if (window.eventBus) {
                                window.eventBus.$off('ivr-tree-group-created', this.ivrTreeGroupCreatedListener)
                        }
                }
        },

        mounted() {
                this.show_search_list = false;
                window._utilities.runIf({
                        dmsFn: () => {
                                this.initializeIvrGroups()
                                this.attachEventListeners()
                        }
                })
        },
        beforeUnmount() {
                window._utilities.runIf({
                        dmsFn: () => { this.detachEventListeners() }
                })
        }
}
</script>
