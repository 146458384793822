<template>
        <div id="searched_campaign" v-cloak=" " v-if="show_search_list">
                <div class="content">
                        <div class="content-header">
                                <h2>Search Campaigns</h2>
                        </div>
                        <div class="content-body">
                                <div class="content-search" data-input=""></div>
                                <div class="calendar-schedule-wrap">
                                        <div class="calendar-wrap">
                                                <div id="caleandar"></div>
                                                <button v-if="canCreateCampaign"
                                                        @click.prevent="$emit('creators-for-create', $event)" class="white-btn"
                                                        type="button">Create Campaign</button>
                                        </div>
                                        <div class="calendar-table">
                                                <!-- ~~~~~~~~~~~~~~~~~~~ Show Campaign ~~~~~~~~~~~~~~~~~~~ -->
                                                <SharedSearchShowCampaign :show_campaign="show_campaign"
                                                        :search-campaigns="searchCampaigns" :campaign="campaign"
                                                        :blast-time="blastTime" :status="status"
                                                        @creators-edit-campaign="onCreatorsEditCampaign"
                                                        @modal-delete-campaign="modalDeleteCampaign" />
                                                <!-- ~~~~~~~~~~~~~~~~~~~ Delete Campaign ~~~~~~~~~~~~~~~~~~~ -->
                                                <CampaignsDeleteCampaign @delete-campaign="deleteCampaign"
                                                        @cancel-delete-campaign="cancelDeleteCampaign" />
                                                <div class="list" v-show="show_campaign_list">
                                                        <div class="list-table">
                                                                <table class="table">
                                                                        <thead>
                                                                                <tr>
                                                                                        <th>Campaign Name</th>
                                                                                        <th>Schedule</th>
                                                                                        <th>Status</th>
                                                                                </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                                <tr v-show="is_loading">
                                                                                        <td colspan="8">Loading...</td>
                                                                                </tr>
                                                                                <tr
                                                                                        v-show="campaigns.data.length == 0 && !is_loading">
                                                                                        <td colspan="8">No Records Found</td>
                                                                                </tr>
                                                                                <tr v-for="campaign in campaigns.data"
                                                                                        v-show="!is_loading">
                                                                                        <td>
                                                                                                <a @click.prevent="showCampaign(campaign.id)"
                                                                                                        class="view-campaign-schedule"
                                                                                                        href="javascript:void(0)">
                                                                                                        {{ campaign.name }}
                                                                                                </a>
                                                                                        </td>
                                                                                        <td>
                                                                                                {{ campaign.blast_time }}{{
                                                                                                        creatorsBlastTime(campaign.blast_date)
                                                                                                }}
                                                                                        </td>
                                                                                        <td
                                                                                                v-bind:class="creatorsStatusTd(campaign.status)">
                                                                                                <span
                                                                                                        v-if="campaign.status == undefined">
                                                                                                        Queue
                                                                                                </span>
                                                                                                <span
                                                                                                        v-else-if="campaign.status == &#39;1&#39;">
                                                                                                        Ongoing
                                                                                                </span>
                                                                                                <span
                                                                                                        v-else-if="campaign.status == &#39;2&#39;">
                                                                                                        Done
                                                                                                </span>
                                                                                                <span
                                                                                                        v-else-if="campaign.status == &#39;3&#39;">
                                                                                                        Failed
                                                                                                </span>
                                                                                                <span
                                                                                                        v-else-if="campaign.status == &#39;4&#39;">
                                                                                                        Uploading
                                                                                                </span>
                                                                                        </td>
                                                                                </tr>
                                                                        </tbody>
                                                                </table>
                                                                <ivr-pagination :pagination="campaigns"
                                                                        @paginate="searchCampaigns"></ivr-pagination>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
        </div>
</template>

<script>
/**
 * source: app/assets/javascript/call_out/search_campaign.js
 */

export default {
        // el: '#searched_campaign',
        props: {
                show_search_list: {
                        type: Boolean,
                        default: false,
                },
                canCreateCampaign: {
                        type: Boolean,
                        required: true,
                },
        },
        data() {
                return {
                        // TODO(aes): is searched_campaign.show_search_list even used?
                        // show_search_list: false,
                        show_campaign_list: false,
                        show_campaign: false,
                        is_loading: false,
                        campaigns: {
                                data: [],
                                total_items: 0,
                                per_page: 2,
                                from: 1,
                                to: 0,
                                current_page: 1,
                                page_total: 0
                        },
                        campaign: '',
                        status: null,
                }
        },
        methods: {

                searchCampaigns() {
                        searched_campaign.show_campaign = false;
                        this.show_campaign = false;
                        this.hideOthers();
                        this.show_campaign_list = true;
                        this.is_loading = true
                        console.log('*** Searching Campaigns - searched_campaign ***');
                        let searchInput = '';

                        console.log("SEARCH INPUT 1");
                        console.log($('#search_input').val());
                        console.log("SEARCH INPUT 1");

                        if ($('.content-search').data('input') == '') {
                                searchInput = $('#search_input').val();
                                console.log("SEARCH INPUT 1");
                                console.log($('#search_input').val());
                                console.log("SEARCH INPUT 1");
                        } else {
                                searchInput = $('.content-search').data('input');
                                console.log("SEARCH INPUT 2");
                                console.log($('.content-search').data('input'));
                                console.log("SEARCH INPUT 2");
                        }
                        let url = '/searchs/index.json';
                        console.log('searchInput: ' + searchInput);

                        axios.get(url, {
                                params: {
                                        page: this.campaigns.current_page,
                                        search: searchInput
                                }
                        }).then(response => {
                                this.show_campaign = false;
                                this.campaigns = response.data;
                                this.is_loading = false;
                                console.log(this.is_loading);
                        }).catch(error => {
                                console.log("FAIL!!!")
                                console.log(error);
                        }).finally(() => {
                                search_campaign.show_search_list = true;
                                this.show_campaign_list = true;
                        });
                },

                showCampaign(cId) {
                        this.show_campaign_list = false;
                        this.show_campaign = true;

                        let url = '/campaigns/' + cId + '.json';
                        axios.get(url).then(response => {
                                this.campaign = response.data;
                                this.campaign.name = response.data.name;
                                this.status = this.campaign.status;
                                console.log("show this from response campaign js");
                        }).catch(error => {
                                console.log("FAIL!!!")
                                console.log(error);
                        }).finally(() => {
                                console.log("show this");
                                this.show_campaign = true;
                        });
                },

                hideOthers() {
                        // TODO(aes): update ivrNumbersApp component
                        // if (document.getElementById("ivr-numbers-app")) {
                        //         ivrNumbersApp.show_numbers = false;
                        // }

                        // TODO(aes): is this page still needed?
                        // if (document.getElementById("plans")) {
                        //         plans.show_plans = false;
                        // }

                        // TODO(aes): implement edit ivr page first
                        // if (document.getElementById("editIvr")) {
                        //         editorTree.show_edit_ivr = false;
                        // }

                        if (document.getElementById("caleandar")) {
                                var currentDate = new Date();
                                this.$emit("creators-for-caleandar", currentDate);
                                setTimeout(function () {
                                        $('.eventday').on('click', function () {
                                                $('.cld-title').css({ "display": "none" });
                                                $(this).find($('.cld-title')).css('display', 'inline');
                                        });
                                }, 500);

                        }
                        $('.ivr-wrapper').html(' ');

                },

                statusTd(status) {
                        switch (status) {
                                case (1):
                                        return "status-done"
                                case (2):
                                        return "status-done"
                                case (3):
                                        return "status-failed"
                                default:
                                        return "status-queue"
                        }
                },

                blastTime(date) {
                        var date = new Date(date);

                        return date.toString("MMMM dd, yyyy");
                },

                retrieveVariableFormat() {
                        this.$emit("creators-check-submit-button");
                        console.log("IVR TREE ID");
                        console.log(this.ivr_tree_id);
                        let url = '/ivr_trees/fetch_variables?it_id=' + this.ivr_tree_id;

                        axios.get(url).then(response => {
                                $('#modal-select-ivr').modal('hide');
                                this.variables = response.data
                        }).catch(error => {
                                console.log(error);
                        }).finally(() => {
                                this.is_loading = false;
                                $('#modal-campaign-create').modal('show');
                        });
                },

                activateButton(id) {
                        this.ivr_tree_id = id;

                        $("#ivr_next").addClass("blue-btn");
                        $("#ivr_next").removeClass("gray-btn");
                        $("#ivr_next").css({ "pointer-events": "auto" });
                },

                showCampaign(cId) {
                        this.show_campaign_list = false;
                        this.show_campaign = true;

                        let url = '/campaigns/' + cId + '.json';
                        axios.get(url).then(response => {
                                this.campaign = response.data;
                                this.campaign.name = response.data.name;
                                this.status = this.campaign.status;
                                console.log("show this from response campaign js");
                        }).catch(error => {
                                console.log("FAIL!!!")
                                console.log(error);
                        }).finally(() => {
                                console.log("show this");
                                this.show_campaign = true;
                        });
                },

                modalDeleteCampaign(cId) {
                        this.campaign_for_deletion = cId;
                        $("#modal-campaign-delete").modal('show');
                },

                deleteCampaign() {
                        $("#modal-campaign-delete").modal('hide');
                        let url = '/campaigns/' + this.campaign_for_deletion;

                        axios.delete(url).then(response => {
                                $("#modal-campaign-delete-success").modal('show');
                                this.getCampaigns();
                                this.show_campaign = false;
                                this.show_campaign_list = true;
                                this.campaign_for_deletion = '';
                                this.accounts = response.data;
                        }).catch(error => {
                                console.log("FAIL!!!")
                                console.log(error);
                        }).finally(() => this.is_loading = false);
                },

                cancelDeleteCampaign() {
                        this.campaign_for_deletion = '';
                        $("#modal-campaign-delete").modal('hide');
                },

                showCaleandarInfo(e) {
                        let cid = $(e).data('cid');

                        $('.hover-calendar-item-details-wrap#campaign' + cid).removeClass("hide");
                        $(e).closest($('.hover-calendar')).addClass("hide");
                },

                // DUP(aes): SharedCreators.vue
                creatorsBlastTime(date) {
                        console.log("====== blastTime from creators.js")
                        var date = new Date(date);
                        date = date.toLocaleDateString('en', { weekday: 'short', month: 'short', day: "numeric", year: 'numeric' })

                        return " - " + date.toString("MMMM dd, yyyy");
                },

                // DUP(aes): SharedCreators.vue
                creatorsStatusTd(status) {
                        switch (status) {
                                case (1):
                                        return "status-done"
                                case (2):
                                        return "status-failed"
                                default:
                                        return "status-queue"
                        }
                },

                setSearchCampaigns(campaigns) {
                        this.campaigns = campaigns
                },

                setIsLoading(is_loading) {
                        this.is_loading = is_loading
                },

                setShowCampaignList(show_campaign_list) {
                        this.show_campaign_list = show_campaign_list
                },

                onCreatorsEditCampaign(campaignId) {
                        const { $emit } = useNuxtApp()
                        $emit("creators-edit-campaign", campaignId)
                }
        },

        mounted() {
                const { $on } = useNuxtApp()
                $on("searched-campaign-hide-others", this.hideOthers)
                $on("searched-campaign-campaigns", this.setSearchCampaigns)
                $on("searched-campaign-is-loading", this.setIsLoading)
                $on("searched-campaign-show-campaign-list", this.setShowCampaignList)
        },
        beforeUnmount() {
                const { $off } = useNuxtApp()
                $off("searched-campaign-hide-others", this.hideOthers)
                $off("searched-campaign-campaigns", this.setSearchCampaigns)
                $off("searched-campaign-is-loading", this.setIsLoading)
                $off("searched-campaign-show-campaign-list", this.setShowCampaignList)
        }
}
</script>
