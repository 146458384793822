<!-- TODO(aes)
<head>
    = stylesheet_link_tag "vendor"
    = stylesheet_link_tag "application"
</head>
-->

<template>
        <div>
                <GoogleTagManager />
                <div id="wrapper" class="toggled">
                        <div id="two-buttons">
                                <SharedSideNav :canCreateIvrTree="can(P.CreateCallFlow, M.IvrTree)"
                                        :canCreateCampaign="can(P.CreateCampaign, M.Campaign)"
                                        :canViewDashboardCampaign="can(P.ViewDashboard, M.Dashboard)"
                                        :canReadIvrTree="can(P.ViewCallFlow, M.IvrTree)"
                                        :canReadCampaign="can(P.IndexCampaign, M.Campaign)"
                                        :canReadUser="can(P.IndexSubUser, M.User)"
                                        :canDownloadReportsCampaign="can(P.DownloadReports, M.Reports)"
                                        :canViewBillingCampaign="can(P.ViewInvoice, M.Billing)" />
                        </div>
                        <div id="page-content-wrapper">
                                <div class="container-fluid">
                                        <SharedHeader :first-name-letter="firstNameLetter"
                                                @update:show_search_list="onShowSearchList" @logout="onLogout" />
                                        <SharedSearchCampaigns :show_search_list="show_search_list"
                                                :can-create-campaign="can(P.CreateCampaign, M.Campaign)"
                                                @creators-for-create="onForCreate" @creators-for-caleandar="onForCaleandar"
                                                @creators-check-submit-button="onCheckSubmitButton" />
                                        <slot />
                                        <SharedFooter />
                                </div>
                        </div>
                </div>
                <SharedCreators ref="sharedCreators" />
        </div>
</template>

<script setup>
import { init as initDate } from "~/assets/js/vendor/date.js"
import { init as initEasyPieChart } from "~/assets/js/vendor/jquery.easypiechart.min.js"
import { init as initCallOutCustom } from "~/assets/js/call_out/custom.js"
import { init as initUtilities, forceLogout, ACCESS_RIGHT } from "~/assets/js/utilities.js"

import layoutStyle from "~/assets/styles/styles.scss?inline"
useHead({
        style: [layoutStyle]
})

const { MODULE: M, PERMISSION: P } = ACCESS_RIGHT
const { firstLetter, userId, isDmsEnabled, can } = useCredentials()
onBeforeMount(() => {
        window.App = {
                userId: userId(),
                isDmsEnabled: isDmsEnabled(),
        }
})

initUtilities(window)
onMounted(() => {
        initDate()
        initEasyPieChart.call(window)
        initCallOutCustom()
})

const sharedCreators = ref(null)
const onForCreate = (...args) => sharedCreators.value.forCreate(...args)
const onForCaleandar = (...args) => sharedCreators.value.forCaleandar(...args)
const onCheckSubmitButton = (...args) => sharedCreators.value.checkSubmitButton(...args)

const show_search_list = ref(false)
const onShowSearchList = (isShow) => show_search_list.value = isShow

const { $on, $off } = useNuxtApp()
onBeforeMount(() => { $on("creators-for-caleandar", onForCaleandar) })
onBeforeUnmount(() => { $off("creators-for-caleandar", onForCaleandar) })

const firstNameLetter = computed(() => {
        return firstLetter()
})

// NOTE(aes): server should respond with empty set-cookie of "_ives_session"
const onLogout = (message) => {
        if (!confirm(message)) return
        useAuthorizedFetch("/users/sign_out", {
                method: "DELETE",
                onResponse() {
                        forceLogout()
                }
        })
}
</script>
