<template>
        <div class="modal fade" id="modal-ivr-create" role="dialog">
                <div class="modal-dialog">
                        <!-- Modal content -->
                        <div class="modal-content">
                                <div class="modal-header">
                                        <h4 class="modal-title">New Call Flow</h4>
                                        <button class="close" data-dismiss="modal" type="button">×</button>
                                </div>
                                <div class="modal-body">
                                        <SharedVueErrorMessages :show_errors="show_errors" :errors="errors" />
                                        <form @submit.prevent="emit('create-ivr-tree')" class="form">
                                                <div class="left templates-wrap">
                                                        <div class="blank">
                                                                <label class="select-ivr-item">
                                                                        <input type="radio" name="ivr_tree[ivr_template]"
                                                                                id="ivr_tree_ivr_template_blank" value="blank"
                                                                                required="required"
                                                                                @click="emit('check-submit-button-ivr', $event)">
                                                                        <div class="checkmark">
                                                                                <img src="~/assets/images/client/ic_ivr.svg">
                                                                                <h5>Create Blank</h5>
                                                                                <img class="check-img"
                                                                                        src="~/assets/images/client/check_select.svg">
                                                                        </div>
                                                                </label>
                                                        </div>
                                                        <div class="templates">
                                                                <h6>Templates</h6>
                                                                <label class="select-ivr-item">
                                                                        <input type="radio" name="ivr_tree[ivr_template]"
                                                                                id="ivr_tree_ivr_template_billing"
                                                                                value="billing" required="required"
                                                                                @click="emit('check-submit-button-ivr', $event)">
                                                                        <div class="checkmark">
                                                                                <img
                                                                                        src="~/assets/images/client/ic_billing.svg">
                                                                                <h5>Collections</h5>
                                                                                <img class="check-img"
                                                                                        src="~/assets/images/client/check_select.svg">
                                                                        </div>
                                                                </label>
                                                                <label class="select-ivr-item">
                                                                        <input type="radio" name="ivr_tree[ivr_template]"
                                                                                id="ivr_tree_ivr_template_survey" value="survey"
                                                                                required="required"
                                                                                @click="emit('check-submit-button-ivr', $event)">
                                                                        <div class="checkmark">
                                                                                <img src="~/assets/images/client/ic_survey.svg">
                                                                                <h5>Survey</h5>
                                                                                <img class="check-img"
                                                                                        src="~/assets/images/client/check_select.svg">
                                                                        </div>
                                                                </label>
                                                        </div>
                                                </div>

                                                <div class="right">
                                                        <h5>Settings</h5>
                                                        <!-- TODO(aes): should dms be implemented?
                                                        - if user_is_dms_enabled?
                                                          %div
                                                            = f.label :ivr_tree_group_id, 'Group'
                                                            %select#ivr_tree_ivr_tree_group_id{ 'v-model': 'ivr_tree_group_id',
                                                              '@change': 'check-submit-button-ivr',
                                                              name: 'ivr_tree[ivr_tree_group_id]',
                                                              required: "required" }
                                                              %option{ ':value': '-1' } None
                                                              %option{ 'v-for': '{ id, name } in ivrTreeGroups', ':value': 'id' } {{ name }}
                                                        -->
                                                        <div>
                                                                <label for="ivr_tree_name">Call Flow Name</label>
                                                                <input v-model="ivr_name" required="required"
                                                                        placeholder="Untitled" type="text" name="ivr_tree[name]"
                                                                        id="ivr_tree_name" class="title"
                                                                        @keyup="emit('check-submit-button-ivr', $event)">
                                                        </div>
                                                        <label for="ivr_tree_caller_id">Globe Caller ID</label>
                                                        <select name="ivr_tree[caller_id]" id="ivr_tree_caller_id"
                                                                v-model="caller_id"
                                                                @change="emit('check-submit-button-ivr', $event)">
                                                                <option v-for="option in caller_id_select_options" :key="option"
                                                                        :value="option">
                                                                        {{ option }}</option>
                                                        </select>
                                                        <label for="ivr_tree_non_globe_caller_id">Non-Globe Caller ID</label>
                                                        <select name="ivr_tree[non_globe_caller_id]"
                                                                id="ivr_tree_non_globe_caller_id" v-model="non_globe_caller_id"
                                                                @change="emit('check-submit-button-ivr', $event)">
                                                                <option v-for="option in non_globe_caller_id_select_options"
                                                                        :key="option" :value="option">
                                                                        {{ option }}</option>
                                                        </select>
                                                        <label for="ivr_tree_sms_masking">SMS Masking</label>
                                                        <select name="ivr_tree[sms_masking]" id="ivr_tree_sms_masking"
                                                                v-model="sms_masking"
                                                                @change="emit('check-submit-button-ivr', $event)">
                                                                <option v-for="option in sms_masking_for_listing" :key="option"
                                                                        :value="option">
                                                                        {{ option }}</option>
                                                        </select>
                                                        <label for="ivr_tree_voice">Voice</label>
                                                        <select name="ivr_tree[voice]" id="ivr_tree_voice" v-model="voice"
                                                                @change="emit('check-submit-button-ivr', $event)">
                                                                <option v-for="[name, value] in voices" :key="name"
                                                                        :value="value">
                                                                        {{ name }}</option>
                                                        </select>
                                                </div>

                                                <div class="buttons">
                                                        <button class="white-btn" type="button" data-dismiss="modal">
                                                                Close
                                                        </button>
                                                        <button id="new_ivr_create" class="blue-btn" type="submit">
                                                                Create
                                                        </button>
                                                </div>
                                        </form>
                                </div>
                        </div>
                </div>
        </div>
</template>

<script setup>
defineProps([
        "caller_id_select_options",
        "non_globe_caller_id_select_options",
        "sms_masking_for_listing",
        "voices",
        "show_errors",
        "errors",
])

const ivr_name = defineModel("ivr_name")
const caller_id = defineModel("caller_id")
const non_globe_caller_id = defineModel("non_globe_caller_id")
const sms_masking = defineModel("sms_masking")
const voice = defineModel("voice")

const emit = defineEmits([
        "create-ivr-tree",
        "check-submit-button-ivr",
])
</script>
