<template>
        <div id="sidebar-wrapper">
                <ul class="sidebar-nav">
                        <li class="sidebar-brand">
                                <a href="/">
                                        <img src="~/assets/images/client/ic_logo_ives_white.svg">
                                </a>
                        </li>
                        <li v-if="canCreateIvrTree" class="create-ivr">
                                <button @click.prevent="$emit('creators-for-create-ivr')" type="button">
                                        Create Call Flow
                                        <img src="~/assets/images/client/ic_create_ivr.svg">
                                </button>
                        </li>
                        <li v-if="canCreateCampaign" class="create-campaign">
                                <button @click.prevent="$emit('creators-for-create')" type="button">
                                        Create Campaign
                                        <img src="~/assets/images/client/ic_play_circle.svg">
                                </button>
                        </li>
                        <li v-if="canViewDashboardCampaign" :class="{ active: getActiveRoute('/dashboard') }">
                                <a href="/dashboard">
                                        <div><img src="~/assets/images/client/ic_dashboard_white.svg"></div>
                                        Dashboard
                                </a>
                        </li>
                        <!-- TODO(aes): dms files -->
                        <li v-if="canReadIvrTree" :class="{ active: getActiveRoute('/ivr_trees') }">
                                <a href="/ivr_trees">
                                        <div><img src="~/assets/images/client/ic_ivr_white.svg"></div>
                                        Call Flows
                                </a>
                        </li>
                        <li v-if="canReadCampaign" :class="{ active: getActiveRoute('/campaigns') }">
                                <a href="/campaigns">
                                        <div><img src="~/assets/images/client/ic_campaigns_white.svg"></div>
                                        Campaigns
                                </a>
                        </li>
                        <li v-if="canReadUser" :class="{ active: getActiveRoute('/sub_users/users') }">
                                <a href="/sub_users/users">
                                        <div><img src="~/assets/images/client/ic_user.svg"></div>
                                        Users
                                </a>
                        </li>
                        <li v-if="canDownloadReportsCampaign" :class="{ active: getActiveRoute('/call_out/reports') }">
                                <a href="/call_out/reports">
                                        <div><img src="~/assets/images/client/ic_reports_white.svg"></div>
                                        Reports
                                </a>
                        </li>
                        <!-- <li v-if="canViewBillingCampaign" :class="{ active: getActiveRoute('/billings') }">
                                <a href="/billings">
                                        <div><img src="~/assets/images/client/ic_billing_white.svg"></div>
                                        Billing
                                </a>
                        </li> -->
                        <li :class="{ active: getActiveRoute('/help') }">
                                <a href="/help">
                                        <div><img src="~/assets/images/client/ic_help_white.svg"></div>
                                        Help
                                </a>
                        </li>
                        <!-- TODO(aes): implement access control for audit trail -->
                        <li :class="{ active: getActiveRoute('/audit_logs') }">
                                <a href="/audit_logs">
                                        <div><img src="~/assets/images/client/ic_list.svg"></div>
                                        Audit Trail
                                </a>
                        </li>
                </ul>
        </div>
</template>

<script setup>
defineProps([
        "canCreateIvrTree",
        "canCreateCampaign",
        "canViewDashboardCampaign",
        "canReadIvrTree",
        "canReadCampaign",
        "canReadUser",
        "canDownloadReportsCampaign",
        "canViewBillingCampaign",
])
const getActiveRoute = (path) => path === useRoute().path
const { $emit } = useNuxtApp()
</script>
